.news-card {
    min-width: 350px;
    max-width: 350px;
    justify-content: space-around;
    margin-bottom: 30px ;
    img {
        height: 232px ;
        min-height: 232px;
    }
}

.card-deck .card {
    min-width: 325px;
    max-width: 325px;
    min-height: 563px;
    margin-top: 20px;
    margin-bottom: 55px;
    box-shadow: 0 3px 6px rgba(0,0,0,.16);
}

.card-deck {
    flex-flow: unset;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.card__img {
    height: 230px;
}

.card-body {
    cursor: pointer;
}
