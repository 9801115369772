
.product {
    &-link{
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 240px;
        margin-bottom: 50px !important;

        &__image {
            margin-bottom: 15px;
            height: 70px;
            width: 100%;
            margin-bottom: 15px;
            display: flex;
            justify-content: center;
            -webkit-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;

            .img {
                height: 60px;
                width: 60px !important;
                margin: 0 !important;
                @media (max-width: $break-sm) {
                    height: 50px;
                    width: 50px;
                }
            }
        }
        &__name {
            font-weight: 700;
            margin-bottom: 15px;
            font-size: 18px;
            text-align: center;
            -webkit-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
            color: white;
            width: 240px;
        }

        &__copy {
            text-align: center;
            padding: 5px;
            font-size: 17px;
            color: #c2c2c2;
            -webkit-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
            width: 240px;
            @media (max-width: $break-sm) {
                font-size: 14px;
            }
        }
    }
}

.product-link:hover {
    text-decoration: none;
    color: white;

    .product-link__image {
        transform: scale(1.2);
    }

    .product-link__name {
        transform: scale(1.1);
    }

    .product-link__copy {
        color: white;
    }
}

@media (max-width: $break-sm) {
    .product-link {
        margin: 0 auto;

    }
}

@media (min-width: $break-md) {
    .product-link {
        max-width: none;
        width: 33%;
    }
}

@media (min-width: $break-lg) {
    .product-link{
        max-width: 240px;
    }
}


