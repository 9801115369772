.paginator {
    width: 100%;
    display: flex;
    justify-content: center;
}

.page-link {
    color: #666 !important;
}

.page-item.active .page-link {
    background-color: #666 !important;
    border-color: #666 !important;
    color: #fff !important;
}
