// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Import scss
@import "./faq.scss";
@import "./icon.scss";
@import "./content.scss";
@import "./navbar.scss";
@import "./btn.scss";
@import "./login.scss";
@import "./contact.scss";
@import "./card.scss";
@import "./product.scss";
@import "./paginator.scss";
@import "./layout/footer.scss";
@import "./img.scss";
@import "./link.scss";
@import "./article.scss";
@import "./mobile.scss";

html {
    box-sizing: border-box;
}

body {
    background: #fff;
    scroll-behavior: smooth;
    font-family: Arial, Helvetica, sans-serif;
    @media (max-width: $break-sm) {
        font-size: 0.78rem;
    }
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

.main {
    min-height: $main-height;
    height: auto;
}

.fade-in {
    animation: fadein 1s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
