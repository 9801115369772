.contacts {
    margin-bottom: 45px;

    &__wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 80px;
        @media (max-width: $break-sm) {
            padding: 30px;
        }
    }

    &__header {
        color: #57ab27;
        text-align: center;
        font-weight: 700;
        margin-bottom: 15px;

        .h3 {
            font-size: 56px !important;
            margin-top: 20px;
            margin-bottom: 10px;

            @media (max-width: $break-sm) {
                font-size: 34px !important;
            }
        }
    }

    &__description {
        text-align: center;
        font-size: 1.5rem;
        @media (max-width: $break-sm) {
            font-size: 1.0rem;
        }
    }

    &__data {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-around;
        @media (max-width: $break-sm) {
            flex-wrap: wrap;
        }
    }

    &__people {
        display: inherit;
        margin: 50px 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.contact{
    font-size: 18px;
    color: #666;
    min-height: 100vh;
    @media (max-width: $break-sm) {
        font-size: 14px;
    }

    &__person {

        &__name {
            text-transform: uppercase;
            margin-bottom: 10px;
            font-size: 24px;
            font-weight: 700;
            color: #333;
            width: 300px;

            @media (max-width: $break-sm) {
                width: auto;
                font-size: 18px;
            }
        }

        &__icon {
            font-size: 18px;
            height: 20px !important;
            width: 20px !important;
            margin-right: 5px;

            &-with-link {
                white-space: nowrap;
                margin-bottom: 10px;
                height: 25px;
            }

            &-with-link:hover {
                color: #666;
            }
        }
    }

    a{
        text-decoration: none;
        color: #666;
    }

    a:hover {
        text-decoration: underline;
    }
}
