.footer {
    display: flex;
    min-height: $footer-height;
    padding: 60px 0 15px;
    background-color: #333;
    color: #c2c2c2;

  &__locale {
    padding: calc((6vh - 42px) / 2) 0;
    font-weight: bold;

    select {
      cursor: pointer;
      border: 0;
      background: #fff;
      box-shadow: none;
      color: #000;

      &:hover,
      &:active,
      &:focus {
        box-shadow: none;
      }
    }
  }

  &__links {
    height: 6vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: bold;
  }

    &__top {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 16px;
        margin-bottom: 30px;
        color: #c2c2c2;
            -webkit-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
        img {
            max-width: 300px;
            margin-top: 15px;
            width: auto;
            @media (max-width: $break-sm) {
                max-width: 200px;
            }
        }
    }

    &__middle {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-around;
        margin: 40px 0;
        flex-wrap: wrap;
    }

    &__bottom {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        font-size: 14px;

        ul {
            display: flex;
            list-style: none;
            margin: 0;
            padding: 0;
            text-decoration: none;

            li {
                margin: 0 10px;

                a, a:hover {
                    text-decoration: none;
                }
            }
        }
    }

  a,
  a:focus,
  a:visited {
    color: #c2c2c2;
  }

  a:hover {
    color: #fff;
    transition: all 0.2s;
  }
}

.social-media-links {
    height: 30px;
    width: 30px;
    transition: all .5s ease-in-out;
    img {
        height: 100%;
        margin: 0;
    }
}

.social-media-links:hover {
    transform: scale(1.15);
}

.container-footer {
    min-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1300px;
}

@media (max-width: $break-sm) {
    .footer__middle {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
    }
}

@media (min-width: $break-sm) {
    .footer__middle {
        flex-direction: row;
    }
}

@media (max-width: $break-xxs) {
    .footer__bottom {
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
        height: 100px;
    }
}
