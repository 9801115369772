.article {
    &__image {
        width: 100%;
        max-height: 700px;
    }

    &__header {
        padding-right: 50px;
        padding-left: 50px;
        background: #007d40;
        color: white;
    }

    &__body {
        padding: 40px 50px;
    }

    &__title {
        font-size: 1.5rem;
        font-weight: 600 ;
        margin-bottom: 2rem;
        width: 80%;
        @media (max-width: $break-sm) {
            font-size: 0.9rem;
            width: 70%;
        }
        @media (max-width: $break-xs) {
            font-size: 0.8rem;
        }
    }

    &__content {
        padding: 2.5rem;
    }

    &__sub {
            text-align: right;
            line-height: 1.5;
    }
}
