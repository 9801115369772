
// Body
$body-bg: #f8fafc;

// Breakpoints

$break-xxs: 425px;
$break-xs: 576px;
$break-sm: 768px;
$break-md: 1024px;
$break-lg: 1200px;

// Typography
$font-family-sans-serif: "Nunito", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

//semantic attributes
$navbar-height: 90px;
$main-height: calc(100vh - #{$navbar-height});
$footer-height: 60vh;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66D9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
