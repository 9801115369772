.navbar {
    padding: 10px 23px;
    height: $navbar-height;

    @media (max-width: 768px){
        height: 100%;
    }
    @media (min-width: 1200px){
        .container {
            max-width: 100%;
        }
    }

    &-nav {
        margin:auto;
    }

    &-brand {
        margin: 0;
        width: 220px;
    }

    &-shadow {
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
    }
}

.header {
    display: flex;

    &__right {
        width: 55%;
    justify-content: flex-end;
        @media ( max-width: $break-sm) {
            justify-content: flex-start;
            width: 50%;
        }

        @media ( max-width: $break-md) {
            width: 70%;
        }
    }

    &__left {
        width: 45%;
        justify-content: center;

        @media ( max-width: $break-md) {
            width: 30%;
        }
        @media ( max-width: $break-sm) {
            width: 75%;
        }
    }
}

.header__logo {
    width: auto;
    max-height: 75px;
}

.nav-item {
    font-size: 18px;
}

.dropdown {
    font-weight: bold;
}

.dropdown-menu {
    @media(max-width: $break-sm) {
        border: none;
    }
}
