.img {
    &__main {
        width: auto;
    }
}

.background__image {
    background-image: url("/images/cover.svg");
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-size: cover;
    background-position: 50%;
    max-height: calc(100vh - 80px);
}
